import { APIFilter } from '@/utils/api'

export default {
  async selectLlavero (Vue, idllavero) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idllavero', idllavero)
      .addGT('estado', 0)
    const resp = await Vue.$api.call('llavero.select', { filter: apiFilter })
    return [resp.data.result.dataset[0], resp.data.result.metadata]
  }
}
